<template>
  <UsersEditAdmin
          v-if="$store.getters.getIsAdminRights === 'admin'"
          :UEAdmin="UEAdmin"
          :orderFees="orderFees"
          :orderFBAFees="orderFBAFees"
          :shipStation="shipStation"
          :isAdminType="isAdminType"
          @changeUser="changeUser"
          @reload="reload"
          @saveUser="changeUser"
  />
</template>

<script>
  import UsersEditAdmin from "./UsersEditAdmin/UsersEditAdmin";
  import {UserEdit} from "../../models/UserEdit";
  import {userAvatarMixin} from "../../../../../mixins/usersMixins/userAvatarMixin";
  import {PERMISSIONS} from "@/staticData/permissionsStatic";

  export default {
    name: "UsersEdit",

    components: {
      UsersEditAdmin,
    },

    mixins: [userAvatarMixin],

    watch: {
      loadUserRoleAndAuthorizedData() {
        if(this.isSubAdmin && !this._.has(this.currentPermissions, PERMISSIONS.USERS_EDIT)) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      },
    },

    computed: {
      isAdminType() {
        return this.$route.query.adminType === 'true'
      }
    },

    data() {
      return {
        UEAdmin: new UserEdit(),

        orderFees: [],
        orderFBAFees: [],
        shipStation: [],
      }
    },

    mounted() {

      if(this.loadUserRoleAndAuthorizedData && this.isSubAdmin && !this._.has(this.currentPermissions, PERMISSIONS.USERS_EDIT)) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      let userId = this.$route.params.id
      if(userId){
        this.UEAdmin.data.user.setUserId(userId)

        this.getDataUserManage(userId)

        this.getFeeOrderFBMForPresets()

        this.getFeeOrderFBAForPresets()

        this.getFeeShipStationFBMForPresets()
      }

    },

    methods: {

      reload() {
        this.getDataUserManage(this.UEAdmin.data.user.getUserId())
      },

      getDataUserManage(id){
        this.$store.dispatch('getUserManageItem', id).then(response => {
          let responseData = this.getRespData(response)
          let user = responseData.user
          let selfLabelContact = user.self_label_contact
          let userData = user.user_setting

          console.log(23423432);
          console.log(selfLabelContact);

          this.UEAdmin.setHistoryOfChanged(user.activityUUID)

          this.UEAdmin.data.user.setUser(user)
          this.UEAdmin.data.userSelfLabelContact.setUser({user_personal_contact: selfLabelContact})
          this.UEAdmin.data.userAccess.setUserAccessItem(userData, user)
          this.UEAdmin.data.userPresets.setUserPresetsItem(user, this)
          this.UEAdmin.data.userDelivery.setUserDeliveryItem(userData)
          this.UEAdmin.data.userDocuments.setItem(userData)

          this.setUserAvatar(this.UEAdmin.data.user)

          if (user?.model_admin_users && user?.model_admin_users?.length > 0) {
            const query = new this.Query();
            let url = '?';
            let ids = user.model_admin_users.map(item => {
              return item.user_id
            }).join(',')
            let myQuery = query
                .for('posts')

            myQuery.where('UserId', ids)

            url = url + myQuery.limit(1000).page(0).url().split('?')[1]

            this.$store.dispatch('fetchUsers', url).then(result => {
              this.UEAdmin.setAssignedUsers(this.getRespPaginateData(result))
            })
          }

        }).catch(error => this.createErrorLog(error))
      },

      getFeeOrderFBMForPresets(){
        this.$store.dispatch('getFeeOrderFBM').then(response => {
          this.orderFees = this.getRespPaginateData(response)
        })
      },
      getFeeOrderFBAForPresets(){
        this.$store.dispatch('getFeeOrderFBA').then(response => {
          this.orderFBAFees = this.getRespPaginateData(response)
        })
      },

      getFeeShipStationFBMForPresets(){
        this.$store.dispatch('getFeeShipStationFBM').then(response => {
          this.shipStation = this.getRespPaginateData(response)
        })
      },

      async uploadFiles(files) {
        for (const item of files) {
          let type = this.isAdmin ? 'adminUploadUserDocument' : 'uploadUserDocument'

          await this.$store.dispatch(type, {id: this.$route.params.id, data: item}).then((response) => {

            switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                let userDocumentsFiles = this.UEAdmin.data.userDocuments.data.Files
                let newFile = this.getRespData(response)?.smallImageEntity || this.getRespData(response)?.pdf

                if(newFile?.extension !== 'pdf') {
                  newFile.typeBase64 = `image`
                  newFile.base64 = `data:application/${item.extension};base64,` + newFile?.file
                }

                userDocumentsFiles.setDownloadFiles([...userDocumentsFiles.getDownloadFiles(), newFile])
                userDocumentsFiles.data.files.pop()
                break
              }
              /**
               * Validation Error
               */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
              /**
               * Undefined Error
               */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })
        }
      },

      sendDocumentsFiles() {
        let files = this.UEAdmin.data.userDocuments.prepareSave()

        return this.uploadFiles(files)
      },


      changeUser() {
        let UEAdminData = this.UEAdmin.data

        if(!UEAdminData.userPresets.userPresetsValidate()) return
        if(!UEAdminData.userDocuments.userDocumentsValidate()) return
        if(UEAdminData.userAccess.data.ATM && !UEAdminData.userAccess.userAccessValidate()) return

        let commission = this._.has(UEAdminData.userPresets.getWarehouseCommission(), 'value') ?
            parseInt(UEAdminData.userPresets.getWarehouseCommission().value) : -1

        let data = {
          user_id: this.$route.params.id,
          user: {
            "commission" : commission,
            "fulfilment_by_amazon" :
              parseInt(UEAdminData.userAccess.getUserAccessFullfilment().fulfilment_by_amazon),
            "fulfilment_by_merchant" :
              parseInt(UEAdminData.userAccess.getUserAccessFullfilment().fulfilment_by_merchant),
            'locked': UEAdminData.userAccess.getBlockUser(),
            'easy_cabinet': UEAdminData.userAccess.getUseEasyCabinet()
          },
          'shipper_data': UEAdminData.userDelivery.getPrepareUserDeliveryItem(),
          'user_presets': UEAdminData.userPresets.getPrepareUserPresetsItem(),
          'balance_output': UEAdminData.userAccess.getUserAccessItem(),
          'userSettings': UEAdminData.userAccess.getUserUserSettings(),
          'payment': UEAdminData.userAccess.getUserPayment(),
          'fulfilment': {use_merchant: false}
        }

        data['user_presets']['n_mark'] = UEAdminData.userAccess.getStatusNewUser()
        data['user_presets']['documents_checked'] = UEAdminData.userDocuments.getMark()

        data['userSettings']['nova_poshta_global'] = UEAdminData.userDelivery.getNOVAPOSHTAGLOBAL()

        data['userSettings']['fedex_use_poland_warehouse'] = UEAdminData.userDelivery.getFEDEXPolandWarehouse()

        data['userSettings']['one_hour_engraving_cost'] = UEAdminData.userPresets.getOneHourEngravingCost()

        data['userSettings']['self_label_address'] = UEAdminData.userAccess.getChangeAddressLabelsYourself()

        let shipStationType = UEAdminData.userPresets.getShipStationType()
        data['userSettings']['self_create_label_use_easypost'] = shipStationType?.self_create_label_use_easypost
        data['userSettings']['self_create_label_use_vipparcel'] = shipStationType?.self_create_label_use_vipparcel

        data['userSettings']['self_create_label_vipparcel_skipping'] = shipStationType?.self_create_label_use_vipparcel ? UEAdminData.userPresets.getUseZoneSkipping() : false


        if(UEAdminData.userPresets.getOrderFeeForManyProducts()?.id) {
          data['userSettings']['user_order_item_fee_id'] = UEAdminData.userPresets.getOrderFeeForManyProducts().id
        } else {
          data['userSettings']['user_order_item_fee_id'] = null
        }

        if(this.isAdminType) {
          data = {
            user_id: data['user_id'],
            user: {
              locked: data['user']['locked'],
            }
          }
        }

        if (this.isAdminType && UEAdminData.assignedUsers.length > 0) {
          data['assignedUsers'] = UEAdminData.assignedUsers.map(item => {
            return item.id
          })
        }
        else if (this.isAdminType && UEAdminData.assignedUsers.length === 0) {
          data['assignedUsers'] = []
        }

        this.$store.dispatch('changeUserManage', data).then(async response => {

          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')

              if(UEAdminData.userDocuments.data.Files.getFiles().length > 0){
                await this.sendDocumentsFiles()
              }

              this.reload()
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })
      },

    },


  }
</script>

<style scoped>

</style>
